import { AxiosError } from 'axios';
import { NextRequest, NextResponse } from 'next/server';

import { TransactionDetailsUpdated } from '@/features/common-navigation/transactions/transactions.types';
import { PaymentMethodName } from '@/features/shared/payment-method/types';

// Lib imports are ok to be cross imported
import { type SSOServiceProviderName } from '@/lib/sso-service/types';
import { logError } from '@/lib/utils';

import { defaultErrorMessage } from './constants';

export type StringBoolean = 'true' | 'false';

export type Falsey<T> = T | null | undefined;

export type Middleware = (
  request: NextRequest,
) => NextResponse | Promise<NextResponse>;

export type PropsWithAsChild<T = {}> = T & {
  asChild?: boolean;
};

export type Boolbacks = {
  onError?: () => void;
  onSuccess?: () => void;
};

/**
 * Props for the Next.js layout error boundaries
 * @see {@link https://nextjs.org/docs/app/building-your-application/routing/error-handling}
 */
export type ErrorBoundaryProps = {
  error: any;
  reset: () => void;
};

// =============================================================================
// Amount
// =============================================================================
export interface AmountPair {
  sourceAmount: string;
  destinationAmount: string;
}

// =============================================================================
// Currency
// =============================================================================
export interface CurrencyPair {
  sourceCurrencyCode: CurrencyCode;
  destinationCurrencyCode: CurrencyCode;
}

// =============================================================================
// Country
// =============================================================================
export interface CountryPair {
  sourceCountryCode: CountryCode;
  destinationCountryCode: CountryCode;
}

// =============================================================================
// API
// =============================================================================
export const enum HttpMethod {
  Get = 'GET',
  Put = 'PUT',
  Post = 'POST',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

type BaseApiConfig = {
  method: HttpMethod;
};

interface BaseGetApiConfig extends BaseApiConfig {
  queryKey: any[];
}

export interface GetApiConfig extends BaseGetApiConfig {
  url: string;
}

export const isGetApiConfig = (config: any): config is GetApiConfig =>
  Boolean(config.queryKey);

export interface DynamicGetApiConfig extends BaseGetApiConfig {
  url: (params?: any) => string;
}

export const isDynamicGetApiConfig = (
  config: any,
): config is DynamicGetApiConfig => typeof config.url === 'function';

export interface RestApiConfig extends BaseApiConfig {
  url: string;
}

export const isRestApiConfig = (config: any): config is RestApiConfig =>
  typeof config.url === 'string';

export interface DynamicRestApiConfig extends BaseApiConfig {
  url: (params?: any) => string;
}

export const isDynamicRestApiConfig = (
  config: any,
): config is DynamicRestApiConfig => typeof config.url === 'function';

// Base API
export const enum ApiErrorCode {
  InvalidRequest = 'INVALID_REQUEST',
  ResourceNotFound = 'RESOURCE_NOT_FOUND',
}

export interface BaseApiError {
  message: string;
  code: ApiErrorCode;
  fieldErrors?: BaseApiError[];
}

export type ApiError = AxiosError<BaseApiError>;

export function isApiError(error: any): error is ApiError {
  return Boolean(error?.response?.data?.code);
}

export function getApiErrorMessage(error: any): string {
  if (!error) {
    return defaultErrorMessage;
  }

  if (isBeneficiaryApiError(error)) {
    return getBeneficiaryApiErrorMessage(error);
  }

  if (isInterbankTransferApiError(error)) {
    return getInterbankTransferApiErrorMessage(error);
  }

  // If is some other error
  if (!isApiError(error)) {
    if (error instanceof Error) {
      return error.message;
    }

    // Return the default
    return defaultErrorMessage;
  }

  // Get the field errors
  const fieldErrors = error.response?.data.fieldErrors;

  // If fields have errors
  if (fieldErrors && fieldErrors.length > 0) {
    const fieldErrorMessage = fieldErrors[0].message;

    if (fieldErrorMessage) {
      return fieldErrorMessage;
    }
  }

  // Otherwise return the message
  return error.response?.data.message!;
}

// Beneficiary API
export enum BeneficiaryApiErrorCode {
  BeneficiaryNotFound = '400000',
  BeneficiaryAlreadyExists = '400000',
  BeneficiaryAccountNotFoundDuringValidation = 'INVALID_ACCOUNT',
}

export interface BaseBeneficiaryApiError {
  code: BeneficiaryApiErrorCode;
  description: string;
  responseCode: string;
  responseMessage: string;
  success: boolean;
}

export type BeneficiaryApiError = AxiosError<BaseBeneficiaryApiError>;

export function isBeneficiaryApiError(
  error: any,
): error is BeneficiaryApiError {
  return Boolean(
    error?.response?.data?.code && error?.response?.data?.description,
  );
}

export function getBeneficiaryApiErrorMessage(error: any): string {
  if (!error) {
    return defaultErrorMessage;
  }

  // If is some other error
  if (!isBeneficiaryApiError(error)) {
    // Return the default
    return defaultErrorMessage;
  }

  const message = error?.response?.data?.description;

  // If message is JSON
  if (message?.includes('"description"')) {
    try {
      return JSON.parse(message).description;
    } catch (error) {
      logError(error);
      return defaultErrorMessage;
    }
  }

  return message ?? defaultErrorMessage;
}

// Interbank Transfer API
export interface BaseInterbankTransferApiError {
  data: {
    message: string;
    pretty: boolean;
  };
}

export type InterbankTransferApiError =
  AxiosError<BaseInterbankTransferApiError>;

export function isInterbankTransferApiError(
  error: any,
): error is InterbankTransferApiError {
  return Boolean(error?.response?.data?.data?.message);
}

export function getInterbankTransferApiErrorMessage(error: any): string {
  if (!error) {
    return defaultErrorMessage;
  }

  // If is some other error
  if (!isInterbankTransferApiError(error)) {
    // Return the default
    return defaultErrorMessage;
  }

  const { pretty, message } = error.response?.data?.data ?? {};

  // If pretty is false, return the default
  if (!pretty) {
    return defaultErrorMessage;
  }

  return message ?? defaultErrorMessage;
}

// =============================================================================
// Auth
// =============================================================================
export interface AuthCredentials {
  phone: string;
  password: string;
}

export interface SignUpData extends AuthCredentials {
  flow: 'phone' | 'sso';
  /** Whether to allow navigation away from the signup page */
  disableNavigation?: boolean;
}

export interface AuthData extends AuthCredentials {
  flow: 'login' | 'recover-password';
}

export interface PasswordRecoveryData {
  phone: string;
  otp: string;
  newPassword: string;
  verificationId: string;
  disableNavigation?: boolean;
}

export interface SigninWithPhonePayload {
  username: string;
}

export interface SignInWithPhoneResponse {}

export type DeviceType = 'WEB' | 'ANDROID' | 'IOS';
export type DeviceStatus = 'ACTIVE' | 'INACTIVE';
export type TwoFactorAuthStep = 'OTP' | 'FACE_MATCH' | 'PASSCODE';
export type TwoFactorAuthStepStatus = 'CREATED' | 'PENDING' | 'COMPLETED';
export type TwoFactorAuthStatus = 'PENDING' | 'COMPLETED' | 'EXPIRED';
export type DevicePlatform = 'MOBILE' | 'DESKTOP';

export interface DeviceInfoPayload {
  deviceId: string;
  type: DeviceType;
  manufacturer: string;
  model: string;
  platform?: DevicePlatform;
}

export interface DeviceInfoCookie extends DeviceInfoPayload {}

export interface DeviceInfoResponse extends DeviceInfoPayload {
  username?: string;
  isKnown?: boolean;
  status?: DeviceStatus;
}

export interface LoginUserPayload {
  passcode?: string;
  idToken?: string;
  '2faId'?: string;
  username?: string;
  deviceInfo: DeviceInfoPayload;
  socialProvider?: SSOServiceProviderName;
}

export interface TwoFaStep {
  step: TwoFactorAuthStep;
  status: TwoFactorAuthStepStatus;
}

export enum MfaAction {
  AddBeneficiary = 'ADD_BENEFICIARY',
  SendMoneyToNewBeneficiary = 'PAYOUT_NEW',
  SendMoneyToExistingBeneficiary = 'PAYOUT_EXISTING',
}

export interface BaseTwoFa {
  id: string;
  deviceInfo: DeviceInfoResponse;
  mfaAction: string;
  username: string;
  requiredSteps: TwoFactorAuthStep[];
  expiryInSeconds: number;
  steps: TwoFaStep[];
  status: TwoFactorAuthStatus;
}

export interface InitTwoFaRequestPayload {
  deviceInfo: DeviceInfoPayload;
  mfaAction: string;
  username: string;
  requiredSteps?: TwoFactorAuthStep[];
}

export type LoginUserErrorResponse = AxiosError<{
  code: string;
  message: string;
  mfa: TwoFaResponse;
}>;

export interface LoginUserSuccessResponse {
  accessToken: string;
  refreshToken: string;
}

export interface TwoFactorAuthStepUrlParams {
  id?: string;
  step?: string;
}

export interface Start2faStepResponse extends RateLimit {
  sumsubAccessToken?: string;
  faceMatchVerificationId?: string;
  alternateChannel?: OTPChannel;
  channel?: OTPChannel;
}

export type OTPChannel = 'EMAIL' | 'PHONE';

export interface TwoFaServiceConfig
  extends Partial<BaseTwoFa>,
    Start2faStepResponse {
  twoFaAction?: string;
  to?: string; // otp sent to
  alternateChannel?: OTPChannel;
  channel?: OTPChannel;
}

export interface TwoFaResponse extends BaseTwoFa {}

export interface Complete2faStepPayload {
  token: string;
}

export const mockAccessToken: string =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJsYXN0TmFtZSI6bnVsbCwicmVzZXRQYXNzd29yZCI6ZmFsc2UsInVzZXJfbmFtZSI6Iis0NDIwNzEyMzY5MTMiLCJhdXRoZW50aWNhdGlvbkRvbWFpbiI6IkNPU01PUyIsInJvbGVzIjpbXSwiYnJhbmNoIjpudWxsLCJwYW5pY01vZGUiOmZhbHNlLCJhdXRob3JpdGllcyI6W10sImNsaWVudF9pZCI6ImZ4LWlkZW50aXR5LXNlcnZpY2UiLCJkZXZpY2VTdGF0dXMiOiJERUZBVUxUIiwiZmlyc3ROYW1lIjpudWxsLCJhdWQiOlsiY29zbW9zLWNvbnRyb2wtc2VydmljZSJdLCJzY29wZSI6WyJwcm9maWxlIl0sIm5hbWUiOiIgIiwidXNlclR5cGUiOiJMb2NhbCBVc2VyIiwiZXhwIjoxNzIwNDY5MDg0LCJqdGkiOiIxNTFmYzE0MC00MGI1LTRkYmItYWRlMC1kNWNkYTk5ZjUyZDIiLCJ1c2VybmFtZSI6Iis0NDIwNzEyMzY5MTMifQ.nGCNLavBfQPxwCcv8D4DUZe2pJbkY9diz4yUiZRwUgJjkAqxRsII9qo2h1KmMEs2_qsR8GiNA_9SoyLNvE0oxWBsqMF4lMiHu0LCd-T3g_1O6AaelxDw8cAch8Qc9T4Gw5hF6SM8T-VjJ6DzX0Uws8GzKCC_cY-zDl1n5LqIb9hKUmLZwvfvxoOHAbP4LNqFyrzjQlm-ShEL5M5m5dVUX2hlMhyiG4yFDkxInXJBoMaWeAu1_l5iB5_4ltV2owzXvzbwA_Gdd9Pty2psEgBbiDavC44-qsZdI_lW2gBqzJoSSC49P7hxVC2CMperMz73wierhF3KWxpFkuHpS-1ADg';

export interface VerifyOtpPayload {
  to: string;
  code: string;
}
export interface VerifyOtpResponse {
  id: string;
  to: string;
  status: string;
}

export interface SigninFlowConfig {
  data: {
    idToken?: string;
    socialProvider?: SSOServiceProviderName;
    username?: string;
    passcode?: string;
    otp?: string;
    isSSO?: boolean;
    phoneInfo?: PhoneInfoValue;
  };
  phoneNumberForm: {
    title: string;
    subtitle: string | React.ReactNode;
    ctaText: string;
    ssoEnabled: boolean;
  };
  passcodeVerification: {
    title: string;
    subtitle: string;
    passcodeLength: number;
  };
  accountLocked?: {
    message: string;
  } | null;
}

export interface RecoverPasscodeFlowConfig {
  data: {
    idToken?: string;
    socialProvider?: SSOServiceProviderName;
    newPasscode?: string;
    confirmedPasscode?: string;
  };
  initiateRecoverPasscode: {
    title: string;
    subtitle: string;
    ssoEnabled: boolean;
    ctaText: string;
  };
  createNewPasscode: {
    title: string;
    subtitle: string;
    passcodeLength: number;
  };
  confirmNewPasscode: {
    title: string;
    subtitle: string;
    passcodeLength: number;
  };
}

// =============================================================================
// Internationalization
// =============================================================================
export type CountryCode = 'GB' | 'NG';
export interface Country {
  name: string;
  code: CountryCode;
  demonym: string;
  callingCode: string;
  currencyCode: CurrencyCode;
  bankCodeLength: {
    min: number;
    max: number;
  };
  backAccountNumberLength: {
    min: number;
    max: number;
  };
}

export type CurrencyCode = 'GBP' | 'NGN';

export interface Currency {
  name: string;
  symbol: string;
  code: CurrencyCode;
  countryCode: string;
}

export interface ArticleThumbnailSchema {
  title: string;
  subtitle: string;
  imageSrc: string;
}

export interface ArticleThumbnailSchema {
  title: string;
  subtitle: string;
  imageSrc: string;
}

export interface ArticleThumbnailSchema {
  title: string;
  subtitle: string;
  imageSrc: string;
}

export interface TransactionFilters {
  timePeriod: { startDate?: string; endDate?: string };
  accounts: { accounts?: string[]; showTransfers?: boolean };
  transferCategories: TransferCategory[];
}

export interface TransactionsConfig {
  filters: TransactionFilters;
  transactions: TransactionResponse[];
  showFilters: boolean;
  size: number;
  page: number;
  totalPages: number;
  totalElements: number;
  searchValue: string;
}

export type TransferType =
  | 'WALLET_DEPOSIT'
  | 'INCOMING_DEPOSIT'
  | 'WALLET_WITHDRAWAL'
  | 'REMITTANCE'
  | 'REMITTANCE_TO_WALLET'
  | 'INTRA_CUSTOMER'
  | 'INTRA_CUSTOMER_EXCHANGE'
  | 'INTRA_BANK_EXCHANGE'
  | 'INTRA_BANK';

export type TransferCategory = 'INFLOW' | 'OUTFLOW' | 'EXCHANGE';

export interface FetchTransactionsPayload {
  startDate?: string;
  endDate?: string;
  transferCategory?: TransferCategory;
  searchValue?: string;
  currencyCode?: string;
  accountId?: string;
  showTransfers?: boolean;
  page?: number;
  size?: number;
}

export interface FetchTransactionsResponse {
  data: {
    content: TransactionResponse[];
    pageNumber: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
  };
}

export interface FetchTransactionDetailsPayload {
  id: string;
}

export type RawDate = string | Date | number;

export interface TransferTransitions {
  message: string;
  timestamp: string;
  subMessage?: string;
  type?: string;
}

export interface BaseTransaction {
  sourceAmount: number;
  destinationAmount: number;
  sourceCurrencyCode: CurrencyCode;
  destinationCurrencyCode: CurrencyCode;
  transferTimestamp: string;
}

export interface TransactionResponse extends BaseTransaction {
  recipient: string;
  status: TransferStatus;
  tag: TransferState;
  direction: TransferDirection;
  destinationAccount: string;
  destinationAccountName: string;
  narration: string;
  sourceAccount: string;
  sourceAccountName: string;
  transferReference: string;
  transferType: TransferType;
  transferDestinationType: string;
  amountToSend: number;
}

export interface ManualBankTransferAccountDetails {
  accountName: string;
  accountNumber: string;
  accountSortCode: string;
  bankCode: string;
  bankName: string;
  message: string;
  paymentReference: string;
  iban: string;
  expirationTime: string;
}

export enum TransferDirection {
  Inwards = 'INWARD',
  Outwards = 'OUTWARD',
  Exchange = 'EXCHANGE',
}

export enum TransferState {
  SUCCESSFUL = 'Successful',
  FAILED = 'Failed',
  CANCELLED = 'Cancelled',
  REFUND_IN_PROGRESS = 'Refund in progress',
  REFUNDED = 'Refunded',
  PENDING = 'Pending',
  WAITING_FOR_PAYMENT = 'Waiting for your payment',
  SENDING = 'Sending',
}

export enum TransferStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Deleted = 'DELETED',
  Completed = 'COMPLETED',
}

export interface TransactionDetailResponse extends BaseTransaction {
  status: string;
  recipientName: string;
  recipientBank: string;
  recipientAccountNumber: string;
  remittanceRate: number;
  customerRate: number;
  transferTransitions: TransferTransitions[];
  direction: TransferDirection;
  manualBankTransferAccountDetails?: ManualBankTransferAccountDetails;
  /** The reason for the transaction as mentioned by the sender */
  reason?: string;
  /** ID of the wallet/account the money was sent to, is absent when destinationPaymentMethod is anything but INTERNAL_ACCOUNT */
  accountId?: string;
  /** Name of the sender, is the user themselves when direction is OUTWARD */
  senderName: string;
  /** ID of the beneficiary, is ID of the user themselves in case direction is INWARD */
  beneficiaryId: string;
  /** ID of the sender's wallet/account, is absent when sourcePaymentMethod is anything but INTERNAL_ACCOUNT */
  senderAccountId?: string;
  /** Method used to make the transaction */
  sourcePaymentMethod: PaymentMethodName;
  /** Method used to receive the transaction */
  destinationPaymentMethod: PaymentMethodName;
}

export interface FetchTransactionDetailsResponse {
  status: string;
  data: /* TransactionDetailResponse |  */ TransactionDetailsUpdated;
}

export type TaskType =
  | 'UPGRADE_KYC'
  | 'VERIFY_EMAIL'
  | 'VERIFY_PHONE'
  | 'DOWNLOAD_APP';

export interface TaskResponse {
  type: TaskType;
  name: string;
  description: string;
  webRedirectUrl: string;
  appRedirectUrl: string;
  base64Icon: string;
  navigationParameters: {
    extraParameter: string;
  };
}

export type UpgradeKycStep = 'start' | 'id-verification' | 'status';

export type FetchTasksResponse = TaskResponse[];

export type DashboardMessagingDialogType =
  | 'KYC_UPGRADE_SUCCESS'
  | 'TRANSFER_SUCCESS';

export type DashboardMessagingAlertType =
  | 'EMAIL_VERIFICATION_SUCCESS'
  | 'PHONE_VERIFICATION_SUCCESS';

export interface OtpUiConfig {
  title: string;
  subtitle: string;
  passcodeLength: number;
  showUsername: boolean;
  showDeviceSpecificTitle?: boolean;
}

export interface PasscodeUiConfig {
  title: string;
  subtitle: string;
  passcodeLength: number;
}

export interface TwoFaUiConfig {
  OTP?: OtpUiConfig;
  FACE_MATCH?: {};
  PASSCODE?: PasscodeUiConfig;
}

export interface Create2faSessionProps
  extends Pick<BaseTwoFa, 'username' | 'mfaAction'> {
  redirectFallback: string;
  redirectTo: string;
}

export interface AccountBalance {
  bookCode: string;
  accountNumber: string;
  ledgerBalance: number;
  availableBalance: number;
}
export interface TotalBalanceResponse {
  status: string;
  data: {
    totalAvailableBalance: number;
    totalLedgerBalance: number;
    accountBalances: AccountBalance[];
  };
}

export interface FetchTransferStatusCountsResponse {
  status: string;
  data: {
    numberOfOpenTransfer: number;
    numberOfCompletedTransfer: number;
  };
}

export type AddressSearchHit = {
  id: string;
  address: string;
};

export type AddressSearchHitsResponse = {
  hits: AddressSearchHit[];
};

export type AddressFormMode = 'postcode' | 'manual';

/**
 * Enum for dialog identifiers used in the application.
 * Can be used in combination with search params to render a dialog asynchronously.
 */
export enum DialogId {
  FundingSuccessful = 'funding-successful',
  TransactionSuccessful = 'transaction-successful',
}

export interface ProfileAddress {
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  verificationStatus?: AddressKycStatus;
}

export type AddressKycStatus =
  | 'NOT_INITIATED'
  | 'INITIATED'
  | 'VERIFIED'
  | 'REJECTED'
  | 'REJECTED_TEMP'
  | 'PENDING'
  | 'ON_HOLD'
  | 'NO_MATCH'
  | 'PARTIAL_MATCH';

export enum HeaderName {
  DeviceId = 'X-Device-ID',
}

export type Headers = Record<string, string | boolean>;

export interface PhoneInfoValue {
  selectedCountry: CountryCode;
  countryCode: string;
  number: string;
}

export const enum KycLevel {
  Level1 = 'gb-personal-1',
  Level2 = 'gb-personal-2',
}

export const enum KycStatus {
  Initial = 'initial',
  OnHold = 'on_hold',
  Pending = 'pending',
  RejectedFinal = 'rejected_final',
  RejectedManual = 'rejected_manual',
  RejectedTemporary = 'rejected_temp',
  Verified = 'verified',
}

export interface ProfileKycStatus {
  kycId: KycLevel;
  status: KycStatus;
  rejectionReason: string;
}

export interface ProfilePhoneInfo {
  countryCode: CountryCode;
  number: string;
}

export interface KYCEvent {
  id: string;
  source: 'SUMSUB';
  kycId: string;
  kycStatus: KycStatus;
  receivedAt: string;
  sourceCreatedAt: string;
}

export interface ContactVerificationStatus {
  isEmailVerified: boolean;
  isPhoneNumberVerified: boolean;
}

export interface BaseProfile {
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  citizenship: string | null;
  countryOfResidence: string;
  phoneInfo: ProfilePhoneInfo;
  address: ProfileAddress;
  username: string;
}
export interface Profile extends BaseProfile {
  id: string;
  sumsubApplicantId?: string;
  kycEventResponses?: any[];
  cbaPersonaId?: string;
  contactVerificationStatus?: ContactVerificationStatus;
  kycEvents?: KYCEvent[];
  profileType?: 'PERSONAL' | 'BUSINESS';
  currentKyc?: null | ProfileKycStatus;
  profileImageUrl?: string;
  marketingOptin?: StayTunedResponse;
  status?: EddProfileStatus;
  edds?: Edd[];
  phone?: string;
  canContactUpdate?: boolean;
}

export type StayTunedStatusType = 'PENDING' | 'PROVIDED';

export type StayTunedResponse = {
  status?: StayTunedStatusType;
  subscribed?: boolean;
};

export type EddLevel = 'LEVEL_1_2' | 'LEVEL_3';

export type EddStatus =
  | 'INITIAL'
  | 'PENDING'
  | 'COMPLETED'
  | 'IN_GRACE_PERIOD'
  | 'REJECTED';

export type EddClearanceType = 'FILEUPLOAD' | 'QUESTIONNAIRE';

export interface Edd {
  id: string;
  level: EddLevel;
  status: EddStatus;
  documentType?: EddFileType;
  documentUploadPending?: boolean;
}

export interface EddClearancePayload {
  amount: number;
  currency: CurrencyCode;
  beneficiaryId: string;
}

export interface EddClearanceResponse {
  id: string;
  status: EddStatus;
  dueDate: string;
  transferCanProceed: boolean;
  edd?: {
    level: EddLevel;
    status: EddStatus;
  };
  clearanceType?: EddClearanceType;
}

export type EddFileType =
  | 'BANK_STATEMENT'
  | 'TAX_RETURNS'
  | 'BUSINESS_INCOME'
  | 'OTHER';

export type EddProfileStatus = 'SUSPENDED' | 'ACTIVE' | 'OFFBOARDED';

export interface RateLimit {
  rateLimit?: {
    reset: number;
  };
}

export interface ApiErrorWithRateLimit extends BaseApiError, RateLimit {}

export interface KycData {
  profileId: string;
}

export type KycInfoResponse = KycInfo[];

export interface KycInfo {
  kydId: string;
  requiresDocuments: boolean;
  passed: boolean;
}

export interface SumsubKycData {
  profileId: string;
  kycId: string;
}
export interface SumsubKycResponse {
  token: string;
}

export interface KycConfigs {
  kycConfigs: KycConfig[];
}

export interface KycUpgradeBenefits {
  type: string;
  name: string;
  description: string;
  descriptionInHTML: string;
}
export interface KycConfig {
  kycId: string;
  requiresDocument: boolean;
  level: number;
  type: string;
  description: string;
  sumsubLevelId: string;
  dailyLimit: number;
  monthlyLimit: number;
  annualLimit: number;
  country: string;
  dailyLimitCurrency: CurrencyCode;
  monthlyLimitCurrency: CurrencyCode;
  annualLimitCurrency: CurrencyCode;
  benefits: KycUpgradeBenefits[] | [];
}
