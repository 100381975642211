'use-client';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers';

export const useResetAuthContexts = () => {
  const { config: signinConfig, setConfig: setSignInContextValues } =
    useSigninFormConfigContext();

  const resetSigninContext = (callback?: Function) => {
    setSignInContextValues({
      ...signinConfig,
      data: { username: '', passcode: '', otp: '', idToken: '', isSSO: false },
    });
    typeof callback === 'function' && callback();
  };

  return { resetSigninContext };
};
