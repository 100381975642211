'use client';

import { useHttpMutation } from '@/shared/hooks';

import { identityApi } from '@/lib/api';

import { DeviceInfoPayload } from '../types';

type SignoutPayload = {
  deviceInfo?: DeviceInfoPayload;
};

export const useSignoutMutationWrapper = useHttpMutation<SignoutPayload, void>;

export function useSignoutMutation(
  options?: Parameters<typeof useSignoutMutationWrapper>[0],
) {
  return useSignoutMutationWrapper({
    api: identityApi,
    url: '/logouts',
    isAuthorized: true,
    disableErrorToast: true,
    ...options,
  });
}
