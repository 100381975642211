'use client';

import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';

export function useManagedNavigation() {
  const router = useRouter();
  const pathname = usePathname();
  const [navigationStack, setNavigationStack] = useState<string[]>([]);

  useEffect(() => {
    setNavigationStack((prev) => {
      // Avoid adding duplicate entries
      // dont need settings in the stack as this is a popover page
      // and we dont want to circulate it on the stack
      if (prev[prev.length - 1] !== pathname && pathname !== '/settings') {
        return [...prev, pathname];
      }
      return prev;
    });
  }, [pathname]);

  const goBack = (fallbackRoute: string) => {
    if (navigationStack.length > 1) {
      const newStack = [...navigationStack];
      newStack.pop(); // Remove current page
      const previousPage = newStack[newStack.length - 1];
      setNavigationStack(newStack);
      router.replace(previousPage);
    } else {
      // If we're at the bottom of our stack, go to the fallback route
      router.replace(fallbackRoute);
    }
  };

  const navigate = (path: string, useReplace: boolean = false) => {
    if (useReplace) {
      router.replace(path);
    } else {
      router.push(path);
    }
    // We don't need to update navigationStack here as it will be handled by the useEffect
  };

  return {
    goBack,
    navigate,
    currentPath: navigationStack[navigationStack.length - 1],
  };
}
