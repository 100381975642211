'use client';

import { useRouter } from 'next/navigation';
import { useLayoutEffect } from 'react';

interface UseDisableBackOptions {
  isEnabled?: boolean;
}

export const useDisableBack = ({
  isEnabled = false, // default to false, so don't accidentally disable back even we add this hook
}: UseDisableBackOptions = {}) => {
  const router = useRouter();

  useLayoutEffect(() => {
    const handlePopState = (event: PopStateEvent) => {
      event.preventDefault();
      window.history.go(1);
    };

    let cleanupSwipeGestures: (() => void) | undefined;

    if (isEnabled) {
      window.history.pushState(null, '', window.location.href);
      window.addEventListener('popstate', handlePopState);

      // Also prevent swipe gestures when isEnabled is true
      cleanupSwipeGestures = preventBrowserHistorySwipeGestures();
    }

    return () => {
      if (isEnabled) {
        window.removeEventListener('popstate', handlePopState);
      }
      if (cleanupSwipeGestures) {
        cleanupSwipeGestures();
      }
    };
  }, [router, window.location.href, isEnabled]);
};

function preventBrowserHistorySwipeGestures() {
  function touchStart(ev: TouchEvent) {
    if (ev.touches.length === 1) {
      const touch = ev.touches[0];
      if (
        touch.clientX < window.innerWidth * 0.1 ||
        touch.clientX > window.innerWidth * 0.9
      ) {
        ev.preventDefault();
      }
    }
  }
  // Safari defaults to passive: true for the touchstart event, so we need to explicitly specify false
  // See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
  const options: AddEventListenerOptions = { passive: false };
  window.addEventListener('touchstart', touchStart, options);
  return () => window.removeEventListener('touchstart', touchStart, options);
}
