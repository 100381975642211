import { useMediaQuery } from '@kamona/utils-v2';

export const useDeviceDetect = () => {
  const isTablet = useMediaQuery({
    minWidth: 744,
    maxWidth: 1023,
  });
  const isMobile = useMediaQuery({ maxWidth: 743 });
  const isTabletOrMobile = isTablet || isMobile;
  //TODO: for now its fine, but in future if we would need very speficic resolutions for desktop and larger screens, we need to refactor
  const isDesktop = !isTabletOrMobile;
  const isDesktOrTablet = isDesktop || isTablet;

  return {
    isTablet,
    isMobile,
    isTabletOrMobile,
    isDesktop,
    isDesktOrTablet,
  };
};
