import {
  DeviceStatus,
  DeviceType,
  TwoFaStep,
  TwoFactorAuthStatus,
  TwoFactorAuthStep,
} from './types';

export const defaultErrorMessage = 'We hit a snag, please retry';

export const NUMBERS_PATTERN = /^\d+$/;

export const EMAIL_PATTERN =
  /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;

export const LETTERS_AND_SYMBOLS_PATTERN =
  /^[A-Za-z\s!~@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;

export const LETTERS_SYBOLS_AND_NUMBERS_PATTERN =
  /^[A-Za-z0-9\s!~@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$/;

export const mockStart2FasResponse = {
  sumsubAccessToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL2V4YW1wbGUuYXV0aDAuY29tLyIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4YW1wbGUuY29tL2NhbGFuZGFyL3YxLyIsInN1YiI6InVzcl8xMjMiLCJpYXQiOjE0NTg3ODU3OTYsImV4cCI6MTQ1ODg3MjE5Nn0.CA7eaHjIHz5NxeIJoFK9krqaeZrPLwmMmgI_XiQiIkQ',
  faceMatchVerificationId: 'xyz',
};

export const MAX_KYC1_VERIFICATION_ATTEMPTS = 2;

export const mockUseComplete2faStepPayload = {
  token: 'xyz',
};

export const mockCreate2faSessionResponse = {
  id: 'xyz',
  deviceInfo: {
    deviceId: 'xyz',
    type: 'WEB' as DeviceType,
    manufacturer: 'Apple',
    model: 'MacIntel',
    username: '+447911123456',
    isKnown: true,
    status: 'ACTIVE' as DeviceStatus,
  },
  mfaAction: 'LOGIN',
  username: '+447911123456',
  requiredSteps: ['OTP'] as TwoFactorAuthStep[],
  expiryInSeconds: 300,
  steps: [
    {
      step: 'OTP',
      status: 'CREATED',
    },
  ] as TwoFaStep[],
  status: 'PENDING' as TwoFactorAuthStatus,
};

export const mock2FasByIdResponse = {
  id: 'xyz',
  deviceInfo: {
    deviceId: 'xyz',
    type: 'ANDROID' as DeviceType,
    manufacturer: 'Samsung',
    model: 'Galaxy S10',
    username: '+449167798322',
    isKnown: true,
    status: 'ACTIVE' as DeviceStatus,
  },
  mfaAction: 'LOGIN',
  username: '+449167798322',
  requiredSteps: ['OTP'] as TwoFactorAuthStep[],
  expiryInSeconds: 300,
  steps: [
    {
      step: 'OTP',
      status: 'CREATED',
    },
  ] as TwoFaStep[],
  status: 'PENDING' as TwoFactorAuthStatus,
};

export const mockCreate2faSessionPayload = {
  deviceInfo: {
    deviceId: 'xyz',
    type: 'WEB' as DeviceType,
    manufacturer: 'Apple',
    model: 'MacIntel',
  },
  mfaAction: 'LOGIN',
  username: '+447911123456',
  requiredSteps: ['OTP'] as TwoFactorAuthStep[],
  expiryInSeconds: 300,
};
