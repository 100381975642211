'use client';

import { useHttpQuery } from '@/shared/hooks';

import { CurrencyCode } from '../types';

/** @deprecated Use `TransferLimits` from `@/features/shared/transfer-limits/transfer-limits.types` instead */
export interface TransferLimits {
  daily: {
    availableLimit: number;
    totalLimit: number;
    currency: CurrencyCode;
  };
  annually: {
    availableLimit: number;
    totalLimit: number;
    currency: CurrencyCode;
  };
  canUpgradeLimit: boolean;
}

const useGetTransferLimitsQuery = useHttpQuery<void, TransferLimits>;

/** @deprecated Use `getTransferLimits` from `@/features/shared/transfer-limits/api/get-transfer-limits` directly in a useQuery hook instead */
export function useGetTransferLimits(
  options?: Partial<Parameters<typeof useGetTransferLimitsQuery>[0]>,
) {
  return useGetTransferLimitsQuery({
    url: `/profile/v1/transfer-limits`,
    method: 'GET',
    queryKey: ['transfer-limits'],
    ...options,
  });
}
